import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/auth';

import React, {
    createContext,
    Dispatch,
    ReactElement,
    SetStateAction,
    useContext,
    useEffect,
    useState,
    useCallback,
} from 'react';

type FirebaseProviderType = {
    children: ReactElement;
    token: string;
};

type FirebaseContextType = {
    user: firebase.User | null;
    setUser: Dispatch<SetStateAction<firebase.User | null>>;
    initialized: boolean;
    setInitialized: Dispatch<SetStateAction<boolean>>;
};

const ctxDefaultValue: FirebaseContextType = {
    user: null,
    setUser: () => {},
    initialized: false,
    setInitialized: () => {},
};

export const FirebaseContext = createContext(ctxDefaultValue);

export const useFirebaseUser = () => {
    const { user, initialized } = useContext(FirebaseContext);
    return initialized && user;
};

const FirebaseProvider = ({ token, children }: FirebaseProviderType) => {
    const [user, setUser] = useState(ctxDefaultValue.user);
    const [initialized, setInitialized] = useState(ctxDefaultValue.initialized);

    const onAuthStateChanged = (authUser: firebase.User | null) => {
        setUser(authUser);
    };

    const initializeFirebase = useCallback(async (token: string) => {
        const {
            REACT_APP_FIREBASE_API_KEY,
            REACT_APP_FIREBASE_AUTH_DOMAIN,
            REACT_APP_FIREBASE_DATABASE_URL,
            REACT_APP_FIREBASE_PROJECT_ID,
            REACT_APP_FIREBASE_STORAGE_BUCKET,
            REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
            REACT_APP_FIREBASE_APP_ID,
        } = process.env;

        const firebaseConfig = {
            apiKey: REACT_APP_FIREBASE_API_KEY,
            authDomain: REACT_APP_FIREBASE_AUTH_DOMAIN,
            databaseURL: REACT_APP_FIREBASE_DATABASE_URL,
            projectId: REACT_APP_FIREBASE_PROJECT_ID,
            storageBucket: REACT_APP_FIREBASE_STORAGE_BUCKET,
            messagingSenderId: REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
            appId: REACT_APP_FIREBASE_APP_ID,
        };

        try {
            //check if firebase app is already initialized; initialize if not, otherwise use existing app
            !firebase.apps.length ? firebase.initializeApp(firebaseConfig) : firebase.app();
            firebase.auth().onAuthStateChanged(onAuthStateChanged);
            await firebase.auth().signInWithCustomToken(token);
            setInitialized(true);
        } catch (e) {
            console.error('Error in Firebase configuration: ', e);
        }
    }, []);

    useEffect(() => {
        if (token) initializeFirebase(token).then(() => console.log('Firebase initialised'));
    }, [token, initializeFirebase]);

    return (
        <FirebaseContext.Provider
            value={{
                user,
                setUser,
                initialized,
                setInitialized,
            }}
        >
            {children}
        </FirebaseContext.Provider>
    );
};

export default FirebaseProvider;
