import React, { useState, useCallback } from 'react';
import { TextStyle, Card, IndexTable, Filters, Select, OptionList, Pagination } from '@shopify/polaris';
import { Payment, Refund, ItemTypes, PaymentStatus } from '../interfaces';
import ItemModal from './ItemModal';

interface ListProps {
    resourceName: { singular: string; plural: string };
    items: Array<Payment | Refund>;
    loading: boolean;
    itemType: ItemTypes;
}

const List = ({ items, resourceName, itemType }: ListProps) => {
    const [statusFilter, setStatusFilter] = useState<string[]>([]);
    const [queryValue, setQueryValue] = useState<string>('');
    const [sortValue, setSortValue] = useState('DATE-DESC');
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage] = useState(15);
    // const [loadingTableResults, setLoadingTableResults] = useState(false)

    const handleTaggedWithChange = useCallback(
        (value) => {
            setCurrentPage(1);
            setStatusFilter([...statusFilter.filter((v) => v === value), ...value]);
        },
        [statusFilter],
    );
    const handleTaggedWithRemove = useCallback(() => {
        setCurrentPage(1);
        setStatusFilter([]);
    }, []);
    const handleQueryValueRemove = useCallback(() => {
        setCurrentPage(1);
        setQueryValue('');
    }, []);
    const handleClearAll = useCallback(() => {
        handleTaggedWithRemove();
        handleQueryValueRemove();
        setCurrentPage(1);
    }, [handleQueryValueRemove, handleTaggedWithRemove]);

    const handleSortChange = useCallback((value) => {
        setSortValue(value);
        setCurrentPage(1);
    }, []);

    const handleQueryChange = useCallback((value) => {
        setQueryValue(value);
        setCurrentPage(1);
    }, []);

    const disambiguateLabel = (key: string, values: string[]) => {
        switch (key) {
            case 'statusFilter':
                return `Status: ${values
                    .map((value: string) => value.charAt(0).toUpperCase() + value.slice(1))
                    .join(', ')}`;
            default:
                return values.map((value: string) => value.charAt(0).toUpperCase() + value.slice(1)).join(', ');
        }
    };

    const isEmpty = (value: string | string[] | undefined) => {
        if (Array.isArray(value)) {
            return value.length === 0;
        } else {
            return value === '' || value == null;
        }
    };

    const filterSections = [
        {
            title: `${resourceName.singular} status`,
            options: Object.values(PaymentStatus).map((value: string) => ({
                value: value,
                label: value.charAt(0).toUpperCase() + value.slice(1),
            })),
        },
    ];

    const filters = [
        {
            key: 'statusFilter',
            label: 'Filters',
            filter: (
                <OptionList
                    onChange={handleTaggedWithChange}
                    sections={filterSections}
                    selected={statusFilter}
                    allowMultiple
                />
            ),
            shortcut: true,
        },
    ];

    const appliedFilters = !isEmpty(statusFilter)
        ? [
              {
                  key: 'statusFilter',
                  label: disambiguateLabel('statusFilter', statusFilter),
                  onRemove: handleTaggedWithRemove,
              },
          ]
        : [];

    const sortOptions = [
        { label: 'Create Date asc', value: 'DATE-ASC' },
        { label: 'Create Date desc', value: 'DATE-DESC' },
        { label: 'Amount asc', value: 'AMOUNT-ASC' },
        { label: 'Amount desc', value: 'AMOUNT-DESC' },
    ];

    const filteredItems = items
        .filter((i: Payment | Refund) => {
            if (statusFilter.length) {
                return statusFilter.includes(i.status);
            }
            return i;
        })
        .filter((i: Payment | Refund) => {
            if (queryValue.length) {
                if (itemType === ItemTypes.PAYMENT) {
                    const item = i as Payment;
                    const {
                        shopify: {
                            paymentRequest: {
                                customer: { billing_address, ...restCustomer },
                                ...restPaymentRequest
                            },
                            ...restShopify
                        },
                        paymentProcessor,
                        ...rest
                    } = item;

                    const flatItem = {
                        ...paymentProcessor,
                        ...billing_address,
                        ...restCustomer,
                        ...restPaymentRequest,
                        ...restShopify,
                        ...rest,
                        updatedAt: rest.updatedAt?.toDate().toLocaleDateString(),
                        updatedAtUTCString: rest.updatedAt?.toDate().toUTCString(),
                        createdAt: rest.createdAt.toDate().toLocaleDateString(),
                        createdAtUTCString: rest.createdAt.toDate().toUTCString(),
                    };

                    return Object.values(flatItem)
                        .toString()
                        .trim()
                        .toLowerCase()
                        .includes(queryValue.trim().toLowerCase());
                }
                if (itemType === ItemTypes.REFUND) {
                    const item = i as Refund;

                    const {
                        shopify: { refundRequest, ...restShopify },
                        paymentProcessor,
                        ...rest
                    } = item;

                    const flatitem = {
                        ...rest,
                        ...paymentProcessor,
                        ...refundRequest,
                        ...restShopify,
                        updatedAt: rest.updatedAt?.toDate().toLocaleDateString(),
                        updatedAtUTCString: rest.updatedAt?.toDate().toUTCString(),
                        createdAt: rest.createdAt.toDate().toLocaleDateString(),
                        createdAtUTCString: rest.createdAt.toDate().toUTCString(),
                    };
                    return Object.values(flatitem)
                        .toString()
                        .trim()
                        .toLowerCase()
                        .includes(queryValue.trim().toLowerCase());
                }
            }
            return i;
        })
        .slice()
        .sort((a: Payment | Refund, b: Payment | Refund) => {
            const aCreateDate = a.createdAt;
            const bCreateDate = b.createdAt;
            const aAmount =
                (itemType === ItemTypes.PAYMENT && parseFloat((a as Payment).shopify.paymentRequest.amount)) ||
                (itemType === ItemTypes.REFUND && parseFloat((a as Refund).shopify.refundRequest.amount));
            const bAmount =
                (itemType === ItemTypes.PAYMENT && parseFloat((b as Payment).shopify.paymentRequest.amount)) ||
                (itemType === ItemTypes.REFUND && parseFloat((b as Refund).shopify.refundRequest.amount));

            switch (sortValue) {
                case 'DATE-DESC':
                    return bCreateDate > aCreateDate ? 1 : -1;
                case 'DATE-ASC':
                    return bCreateDate < aCreateDate ? 1 : -1;
                case 'AMOUNT-DESC':
                    return bAmount > aAmount ? 1 : -1;
                case 'AMOUNT-ASC':
                    return bAmount < aAmount ? 1 : -1;
                default:
                    return bCreateDate > aCreateDate ? 1 : -1;
            }
        });

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const paginatedItems = filteredItems.slice(indexOfFirstItem, indexOfLastItem);

    const rowMarkup = paginatedItems.map((item: Payment | Refund, index: number) => {
        return (
            <IndexTable.Row id={item.id} key={item.id} position={index}>
                <IndexTable.Cell>{item.createdAt.toDate().toLocaleDateString()}</IndexTable.Cell>
                <IndexTable.Cell>
                    <TextStyle variation="strong">{item.id}</TextStyle>
                </IndexTable.Cell>
                <IndexTable.Cell>
                    {item.paymentProcessor?.transactionId || item.paymentProcessor?.orderKey || ''}
                </IndexTable.Cell>
                <IndexTable.Cell>
                    {(itemType === ItemTypes.PAYMENT && (item as Payment).shopify.paymentRequest.amount) ||
                        (itemType === ItemTypes.REFUND && (item as Refund).shopify.refundRequest.amount)}
                </IndexTable.Cell>
                <IndexTable.Cell>{item.status.charAt(0).toUpperCase() + item.status.slice(1)}</IndexTable.Cell>
                <IndexTable.Cell>
                    <ItemModal item={item} itemType={itemType} />
                </IndexTable.Cell>
            </IndexTable.Row>
        );
    });

    return (
        <Card>
            <Card.Section>
                <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                    <div style={{ flex: 1 }}>
                        <Filters
                            queryPlaceholder={`Search ${resourceName.plural}`}
                            queryValue={queryValue}
                            filters={filters}
                            appliedFilters={appliedFilters}
                            onQueryChange={handleQueryChange}
                            onQueryClear={handleQueryValueRemove}
                            onClearAll={handleClearAll}
                        />
                    </div>
                    <div>
                        <Select
                            labelInline
                            label="Sort"
                            options={sortOptions}
                            value={sortValue}
                            onChange={handleSortChange}
                        />
                    </div>
                </div>
            </Card.Section>
            <Card.Section>
                <IndexTable
                    resourceName={resourceName}
                    itemCount={filteredItems.length}
                    hasMoreItems
                    selectable={false}
                    // loading={loadingTableResults}
                    lastColumnSticky
                    headings={[
                        { title: 'Created Date' },
                        { title: 'Shopify Id' },
                        { title: 'Reference' },
                        { title: 'Amount' },
                        { title: 'Status' },
                        { title: 'Details', hidden: true },
                    ]}
                >
                    {rowMarkup}
                </IndexTable>
            </Card.Section>
            <Card.Section>
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    <div style={{ margin: 'auto' }}>
                        <Pagination
                            label={`${indexOfFirstItem + 1} - ${indexOfFirstItem + paginatedItems.length}`}
                            hasPrevious={currentPage > 1}
                            onPrevious={() => setCurrentPage(currentPage - 1)}
                            hasNext={currentPage < filteredItems.length / itemsPerPage}
                            onNext={() => {
                                setCurrentPage(currentPage + 1);
                                window.scrollTo(0, 0);
                            }}
                        />
                    </div>
                    <div style={{ margin: '1rem auto' }}>
                        <TextStyle>
                            Showing {filteredItems?.length} of {filteredItems?.length}{' '}
                            {items.length === 1 ? resourceName.singular : resourceName.plural}
                        </TextStyle>
                    </div>
                </div>
            </Card.Section>
        </Card>
    );
};

export default List;
