import { useState } from 'react';
import { Button, TextField, SettingToggle as PolarisSettingToggle, TextStyle } from '@shopify/polaris';
import { config } from '../config';
const { requiredFields, additionalRequiredFields } = config;

type FieldType =
    | 'text'
    | 'email'
    | 'number'
    | 'password'
    | 'search'
    | 'tel'
    | 'url'
    | 'date'
    | 'datetime-local'
    | 'month'
    | 'time'
    | 'week'
    | 'currency';

export interface RequiredField {
    label: string;
    id: string;
    type: string;
}

export interface FormData extends Record<string, string> {
    id: string;
    key: string;
    merchantApiKey: string;
    testId: string;
    testKey: string;
    testMerchantApiKey: string;
}

export interface AdditionalFormData extends Record<string, any> {
    transactionByTransactionSettlement?: boolean;
    idPrefix?: string;
}

export const getConfig = ({
    formData,
    setFormData,
    requiredFields,
}: {
    formData: FormData;
    setFormData: React.Dispatch<React.SetStateAction<FormData>>;
    requiredFields: RequiredField[];
}) => {
    return requiredFields.map((field: RequiredField) => ({
        ...field,
        value: formData[field.id],
        setState: (newValue: string) => setFormData({ ...formData, [field.id]: newValue }),
    }));
};

export const getAdditionalConfig = ({
    additionalFormData,
    setAdditionalFormData,
    additionalRequiredFields,
}: {
    additionalFormData: AdditionalFormData;
    setAdditionalFormData: React.Dispatch<React.SetStateAction<AdditionalFormData>>;
    additionalRequiredFields: RequiredField[];
}) => {
    return additionalRequiredFields.map((field: RequiredField) => ({
        ...field,
        value: additionalFormData[field.id],
        setState:
            field.type === 'toggle-button'
                ? () => setAdditionalFormData({ ...additionalFormData, [field.id]: !additionalFormData[field.id] })
                : (newValue?: string) => setAdditionalFormData({ ...additionalFormData, [field.id]: newValue }),
    }));
};

const PasswordField = ({
    label,
    id,
    value,
    onChange,
}: {
    label: string;
    id: string;
    value: string;
    onChange: (newValue: string) => void;
}) => {
    const [passwordToggle, setPasswordToggle] = useState<boolean>(false);
    // const [hasBeenBlurred, sethasBeenBlurred] = useState<boolean>(false);
    return (
        <div style={{ position: 'relative' }}>
            <TextField
                autoComplete="off"
                // autoFocus
                label={label}
                id={id}
                value={value}
                onChange={onChange}
                type={passwordToggle ? ('text' as FieldType) : ('password' as FieldType)}
                // error={hasBeenBlurred && !value && `${label} is required`}
                // onBlur={() => sethasBeenBlurred(true)}
            />
            <div
                style={{
                    position: 'absolute',
                    zIndex: 100,
                    right: '10px',
                    top: '32px',
                }}
            >
                <Button onClick={() => setPasswordToggle(!passwordToggle)} plain>
                    {passwordToggle ? 'Hide' : 'Show'}
                </Button>
            </div>
        </div>
    );
};

const InputField = ({
    label,
    id,
    value,
    type,
    onChange,
}: {
    label: string;
    id: string;
    value: string;
    type: string;
    onChange: (newValue: string) => void;
}) => {
    // const [hasBeenBlurred, sethasBeenBlurred] = useState<boolean>(false);
    return (
        <TextField
            autoComplete="off"
            // autoFocus
            label={label}
            id={id}
            value={value}
            onChange={onChange}
            type={type as FieldType}
            // error={hasBeenBlurred && !value && `${label} is required`}
            // onBlur={() => sethasBeenBlurred(true)}
        />
    );
};

const SettingToggle = ({
    label,
    id,
    value,
    type,
    onChange,
}: {
    label: string;
    id: string;
    value: boolean;
    type: string;
    onChange: () => void;
}) => {
    return (
        <PolarisSettingToggle
            action={{
                content: value ? 'Disable' : 'Enable',
                onAction: onChange,
            }}
            enabled={value}
        >
            This setting is <TextStyle variation="strong">{value ? 'enabled' : 'disabled'}</TextStyle>.
        </PolarisSettingToggle>
    );
};

export const useFormConfig = (formData: FormData, setFormData: React.Dispatch<React.SetStateAction<FormData>>) => {
    const formContent = getConfig({ formData, setFormData, requiredFields });

    const formMarkup = formContent.map((field) => {
        if (field.type === 'password')
            return (
                <PasswordField
                    key={field.id}
                    label={field.label}
                    id={field.id}
                    value={field.value}
                    onChange={field.setState}
                />
            );

        return (
            <InputField
                key={field.id}
                label={field.label}
                id={field.id}
                value={field.value}
                type={field.type}
                onChange={field.setState}
            />
        );
    });

    return { formMarkup };
};

export const useAdditionalFormConfig = (
    additionalFormData: AdditionalFormData,
    setAdditionalFormData: React.Dispatch<React.SetStateAction<AdditionalFormData>>,
) => {
    const additionalFormContent = getAdditionalConfig({
        additionalFormData,
        setAdditionalFormData,
        additionalRequiredFields,
    });

    const additionalFormMarkup = additionalFormContent.map((field) => {
        if (field.type === 'toggle-button')
            return (
                <SettingToggle
                    key={field.id}
                    label={field.label}
                    id={field.id}
                    value={field.value}
                    type={field.type}
                    onChange={field.setState}
                />
            );
        if (field.type === 'password')
            return (
                <PasswordField
                    key={field.id}
                    label={field.label}
                    id={field.id}
                    value={field.value}
                    onChange={field.setState}
                />
            );

        return (
            <InputField
                key={field.id}
                label={field.label}
                id={field.id}
                value={field.value}
                type={field.type}
                onChange={field.setState}
            />
        );
    });

    return { additionalFormMarkup };
};
