import { Switch, Route, Redirect } from 'react-router-dom';
import Settings from '../components/Settings';
import Install from '../components/Install';
import Payments from '../components/Payments';
import Refunds from '../components/Refunds';

const Routes = () => {
    return (
        <Switch>
            <Route exact path="/:provider?" component={Settings} />
            <Route exact path="/install/:provider?" component={Install} />
            <Route exact path="/payments/:provider?" component={Payments} />
            <Route exact path="/refunds/:provider?" component={Refunds} />
            <Redirect to="/" />
        </Switch>
    );
};

export default Routes;
