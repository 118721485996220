export const getPath = (): string[] => {
    const path = window.location.pathname;
    return path.split('/');
};

export type QueryParams = {
    [key: string]: string;
};

export const getQueryParams = (): QueryParams => {
    const search = window.location.search.split('&');
    const queryParams: QueryParams = search.reduce((prev, next) => {
        const keyValue = next.split('=');
        return { ...prev, [keyValue[0].replace('?', '')]: keyValue[1] };
    }, {});

    return queryParams;
};

export class ApiError extends Error {
    readonly status: number;
    readonly statusText: string;
    /**
     * A raw text payload of the response; might be actually JSON or plain text, or nothing at all
     */
    readonly payload?: string;

    constructor(status: number, statusText: string, payload?: string) {
        super(`${status}: ${statusText}`);
        this.status = status;
        this.statusText = statusText;
        this.payload = payload;
    }
}

export const checkResponseStatus = async (response: Response): Promise<Response | never> => {
    if (response.ok) {
        return response;
    }
    const payload = await response.text().then(
        (text) => text,
        () => undefined,
    );
    throw new ApiError(response.status, response.statusText, payload);
};
