interface Config {
    client: { id: string; label: string };
    requiredFields: { label: string; id: string; type: string; ref: string; test: boolean }[];
    additionalSettings:
        | {
              type: string;
              component: { type: string; hasAction: boolean; action: (value: string) => void };
              label: string;
              getDescription: (value: string) => string;
              value: string;
          }[]
        | [];
    additionalRequiredFields:
        | { label: string; id: string; type: string; ref: string; test?: boolean; initialValue: string | boolean }[]
        | [];
}

export const config: Config = {
    client: { id: 'cm-com', label: 'CM Payments' },
    requiredFields: [
        { label: 'Production Merchant Id', id: 'id', type: 'text', ref: 'merchantKey', test: false },
        { label: 'Production Merchant Key', id: 'key', type: 'text', ref: 'merchantSecret', test: false },
        {
            label: 'Production Merchant Api Key',
            id: 'merchantApiKey',
            type: 'text',
            ref: 'merchantApiKey',
            test: false,
        },
        { label: 'Test Merchant Id', id: 'testId', type: 'text', ref: 'testMerchantKey', test: true },
        { label: 'Test Merchant Key', id: 'testKey', type: 'text', ref: 'testMerchantSecret', test: true },
        {
            label: 'Test Merchant Api Key',
            id: 'testMerchantApiKey',
            type: 'text',
            ref: 'testMerchantApiKey',
            test: true,
        },
    ],
    additionalSettings: [
        {
            type: 'updateUrl',
            component: {
                type: 'textfield',
                hasAction: true,
                action: (value: string) => navigator.clipboard.writeText(value),
            },
            label: 'Update Url',
            getDescription: (name: string) => `Save the Update URL in the ${name} dashboard.`,
            value:
                process.env.REACT_APP_API_HOST +
                // eslint-disable-next-line no-template-curly-in-string
                '/status?order_reference=${order_reference}&order_key=${order_key}&merchant_name=${merchant_name}',
        },
        {
            type: 'idPrefix',
            component: {
                type: 'text',
                hasAction: true,
                action: () => null,
            },
            label: 'Shopify Transaction Id Prefix',
            getDescription: (name: string) => `Save a custom prefix to be added to payment and refund references.`,
            value:
                process.env.REACT_APP_API_HOST +
                // eslint-disable-next-line no-template-curly-in-string
                '/status?order_reference=${order_reference}&order_key=${order_key}&merchant_name=${merchant_name}',
        },
    ],
    additionalRequiredFields: [
        {
            label: 'Reference Prefix',
            id: 'idPrefix',
            type: 'checkbox-text',
            ref: 'idPrefix',
            initialValue: '',
        },
    ],
};

type EnvCredential = Record<string, string | undefined>;

const envCredentials: EnvCredential = {
    TEST_MERCHANT_ID: process.env.REACT_APP_TEST_MERCHANT_ID,
    TEST_MERCHANT_KEY: process.env.REACT_APP_TEST_MERCHANT_KEY,
    TEST_MERCHANT_API_KEY: process.env.REACT_APP_TEST_MERCHANT_API_KEY,
};

const CredentialLabels: Record<string, string> = {
    TEST_MERCHANT_ID: 'Test Merchant Id',
    TEST_MERCHANT_KEY: 'Test Merchant Key',
    TEST_MERCHANT_API_KEY: 'Test Merchant Api Key',
};

export const checkEnvForCredentials = (): EnvCredential[] | undefined => {
    const foundCredentials = Object.keys(envCredentials).filter((key) => envCredentials[key] !== undefined);

    if (foundCredentials.length) {
        return foundCredentials.map((cred) => ({ label: CredentialLabels[cred], value: envCredentials[cred] }));
    } else {
        return;
    }
};
